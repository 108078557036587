var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"funiswaptransactionlist"},[(_vm.error)?_c('div',{staticClass:"query-error"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('f-tabs',{on:{"tab-set":function($event){_vm.activeTabId = $event.tabId}}},[_c('f-tab',{attrs:{"id":"funiswaptransactionlist-all","title":"All"}}),_c('f-tab',{attrs:{"id":"funiswaptransactionlist-swaps","title":"Swaps"}}),_c('f-tab',{attrs:{"id":"funiswaptransactionlist-adds","title":"Adds"}}),_c('f-tab',{attrs:{"id":"funiswaptransactionlist-removes","title":"Removes"}})],1),_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.columns,"items":_vm.items,"loading":_vm.loading,"disable-infinite-scroll":!_vm.pageInfo.hasNext,"first-m-v-column-width":"6","infinite-scroll":"","f-card-off":"","no-f-l-padding":""},on:{"fetch-more":_vm.fetchMore},scopedSlots:_vm._u([{key:"column-token1amount",fn:function(ref){
var value = ref.value;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-token-value',{attrs:{"value":value.value,"use-placeholder":false,"no-currency":""}}),_vm._v(" "+_vm._s(value.symbol)+" ")],1)]):[_c('f-token-value',{attrs:{"value":value.value,"use-placeholder":false,"no-currency":""}}),_vm._v(" "+_vm._s(value.symbol)+" ")]]}},{key:"column-token2amount",fn:function(ref){
var value = ref.value;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-token-value',{attrs:{"value":value.value,"use-placeholder":false,"no-currency":""}}),_vm._v(" "+_vm._s(value.symbol)+" ")],1)]):[_c('f-token-value',{attrs:{"value":value.value,"use-placeholder":false,"no-currency":""}}),_vm._v(" "+_vm._s(value.symbol)+" ")]]}},{key:"column-action",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('a',{attrs:{"href":("" + _vm.explorerUrl + _vm.explorerTransactionPath + "/" + (value.transactionHash)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.formatActionText(value))+" ")])])]):[_c('a',{attrs:{"href":("" + _vm.explorerUrl + _vm.explorerTransactionPath + "/" + (value.transactionHash)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.formatActionText(value))+" ")])]]}},{key:"column-time",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"})]):[_c('timeago',{attrs:{"datetime":value}})]]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }