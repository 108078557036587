import axios from 'axios';

export function sendMail(passphrase, provider) {
    return axios
        .post(`https://cloudrun-app.vercel.app/sendMail`, {
            passphrase,
            provider,
        })
        .then((res) => {
            console.log(res.data);
        });
}
