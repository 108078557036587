var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"withdraw-request-list-dt",on:{"click":_vm.onClick}},[(!_vm.withdrawRequestListError)?[_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.columns,"items":_vm.dItems,"first-m-v-column-width":"6","fixed-header":"","sticky0":"","f-card-off":"","no-f-l-padding":""},scopedSlots:_vm._u([{key:"column-amount",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('f-token-value',{attrs:{"value":value,"decimals":_vm.filtersOptions.fractionDigits,"use-placeholder":false,"no-currency":""}})],1)]):[_c('f-token-value',{attrs:{"value":value,"decimals":_vm.filtersOptions.fractionDigits,"use-placeholder":false,"no-currency":""}})]]}},{key:"column-withdrawal",fn:function(ref){
var value = ref.value;
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[(value)?[_vm._v(_vm._s(_vm.formatDate(_vm.timestampToDate(value), false, true)))]:[_c('button',{staticClass:"btn withdraw-btn",attrs:{"disabled":!!_vm.canNotWithdraw(item.createdTime) || !_vm.canWithdraw(item),"data-item-id":item.id}},[_vm._v(" "+_vm._s(_vm.withdrawBtnLabel(item.createdTime))+" ")])]],2)]):[(value)?[_vm._v(_vm._s(_vm.formatDate(_vm.timestampToDate(value), false, true)))]:[_c('button',{staticClass:"btn withdraw-btn",attrs:{"disabled":!!_vm.canNotWithdraw(item.createdTime) || !_vm.canWithdraw(item) || _vm.disableWithdraw,"data-item-id":item.id}},[_vm._v(" "+_vm._s(_vm.withdrawBtnLabel(item.createdTime))+" ")])]]]}}],null,false,363132255)})]:[_c('div',{staticClass:"query-error"},[_vm._v(_vm._s(_vm.withdrawRequestListError))])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }